import { RulerDisplayLevel } from "./puzzle-data";

export class RulerDisplayLevelController{
    private wrongAnswerThreshold = 2;
    private wrongAnswerCount = 0;
    private targetLevel: RulerDisplayLevel = 'allParts';
  
    constructor(){}
  
    public addWrongAnswer(){
      if(this.wrongAnswerCount >= this.wrongAnswerThreshold) return;
      this.wrongAnswerCount++;
    }
  
    public getLevel(){
      return this.wrongAnswerCount >= this.wrongAnswerThreshold? this.targetLevel: undefined;
    }
}