import { Container, Rectangle } from 'pixi.js';
import { Disposable } from '../core/interfaces/disposable';
import { Rect } from '../core/math/rectangle';
import { CharacterView } from './character-view';
import { PuzzleState, reducer, selectComplete } from './puzzle-state';
import { PuzzleStateController } from './puzzle-state-controller';
import { ActionData } from './puzzle-data';
import { VehicleView } from './entityviews/vehicle-view';
import { wait } from '../core/async/awaitable';
import { BatteryView } from './entityviews/battery-view';
import { CancellationToken, CancellationTokenSource } from '../core/async/cancellations';
import { windowPostMessage } from '@puzzles/core/post-message';
import { PuzzleActivityViewController } from './puzzle-activity-view-controller';
import { approximatelyEquals } from '@puzzles/core/math/utility';
import { LineRulerView } from './ruler-view';
import { PuzzleSkinData } from './puzzle-skin';
import { RulerDisplayLevelController } from './ruler-display-level-controller';

export class PuzzleActivityController implements Disposable {
  state: PuzzleStateController;
  view: PuzzleActivityViewController;
  private rulerDisplayLevelController?: RulerDisplayLevelController;
  private actionCancellation?: CancellationTokenSource;
  private triesNumber = 0;

  onComplete: ((triesNumber: number) => boolean) | undefined;

  constructor(
    state: PuzzleState,
    skin: PuzzleSkinData,
    container: Container,
    screen: Rect,
    lauchAnimationDelay: number,
    viewFadeIn: boolean,
  ) {
    this.state = new PuzzleStateController(state);
    
    this.rulerDisplayLevelController = new RulerDisplayLevelController();

    this.view = new PuzzleActivityViewController(
      state,
      skin,
      container,
      screen,
      this.onAction.bind(this),
      this.reset.bind(this),
      lauchAnimationDelay,
      viewFadeIn,
    );
  }

  resize(container: Rectangle) {
    this.view.resize(container);
  }

  async onAction(actions: ActionData[]) {
    if (this.actionCancellation) return;

    this.triesNumber++;

    this.actionCancellation = new CancellationTokenSource();
    const token = this.actionCancellation.token;

    let state = this.state.getState();
    for (let action of actions) {
      state = reducer(state, action);
    }

    if (token.isCancelled) return;
    await wait(100);

    let duck = this.view.puzzleView!.selectEntity<CharacterView>((e) => e instanceof CharacterView);
    let vehicle = this.view.puzzleView!.selectEntity<VehicleView>((e) => e instanceof VehicleView);
    vehicle.targetDuck = duck;

    let index = 0;
    for (let event of state.events) {
      if (token.isCancelled) return;

      switch (event.type) {
        case 'move':
          {
            if (token.isCancelled) return;
            this.view.actionViewController.focusAction(index);
            index++;
            await wait(100);
            if (token.isCancelled) return;

            const ruler = this.view.characterRulers[event.from.ruler];
            let success = await ruler.moveFromTo(event.from, event.to, event.action);

            if (token.isCancelled) return;
            if (!success) {
              await this.onActionBreak(duck, token);
              return;
            } else {
              if (ruler.ruler instanceof LineRulerView)
                (ruler.ruler as LineRulerView).addPointerMarker(event.to.numerator / event.to.denominator);
            }
          }
          break;

        case 'teleport':
          {
            const ruler = this.view.characterRulers[event.ruler];
            var transform = ruler.ruler.getTransform(event);
            if (token.isCancelled) return;
            await ruler.character.teleportTo(transform[0], transform[1]);

            if (token.isCancelled) return;
            if (ruler.ruler instanceof LineRulerView)
              (ruler.ruler as LineRulerView).addPointerMarker(event.numerator / event.denominator);
            // this.view.characterRulers.forEach(c => c.hide());
          }
          break;

        case 'charge':
          {
            const ruler = state.rulers[event.ruler];
            const fraction =
              ruler.type === 'line' || ruler.type === 'segment'
                ? event.numerator / event.denominator
                : (event.numerator / event.denominator) % 1;
            this.view.puzzleView
              ?.selectEntity<BatteryView>((e) => e instanceof BatteryView, event.ruler, fraction)
              .pickAnimation();
            vehicle.chargeEnergy();
            if (token.isCancelled) return;
            await wait(150);
            if (token.isCancelled) return;
            duck.happy();
            if (token.isCancelled) return;
            await wait(400);
          }
          break;

        case 'complete':
          break;
      }
    }

    await this.onActionFinished(state, duck, vehicle, token);
    this.actionCancellation = undefined;
  }

  private async onActionBreak(duck: CharacterView, token: CancellationToken) {
    this.rulerDisplayLevelController?.addWrongAnswer();

    if (token.isCancelled) return;
    this.view.puzzleView!.characterMarker.show(false);
    await duck.fallDown();
    if (token.isCancelled) return;
    await wait(800);
    if (token.isCancelled) return;
    this.reset(true);
  }

  private async onActionFinished(
    state: PuzzleState,
    duck: CharacterView,
    vehicle: VehicleView,
    token: CancellationToken
  ) {
    this.view.puzzleView!.characterMarker.show(false);
    if (selectComplete(state)) {
      if (token.isCancelled) return;
      this.view.actionViewController.disableActionButton(true);
      await vehicle.launch(true);
      if (token.isCancelled) return;
      await wait(500);

      const completionTries = this.triesNumber;
      this.triesNumber = 0;
      if (this.onComplete?.call(this, completionTries)) {
        windowPostMessage('complete!');
        return;
      }
    } else {
      this.rulerDisplayLevelController?.addWrongAnswer();

      if (token.isCancelled) return;
      if (
        approximatelyEquals(duck.position.x, vehicle.position.x) &&
        approximatelyEquals(duck.position.y, vehicle.position.y)
      ) {
        await vehicle.launch(false);
        if (token.isCancelled) return;
        await wait(500);
      } else {
        await wait(300);
        duck.sad();
        if (token.isCancelled) return;
        await wait(1000);
      }
    }

    if (token.isCancelled) return;
    this.reset(true);
  }

  private reset(refreshDisplayLevel = false) {
    if (this.actionCancellation) this.actionCancellation.cancel();
    this.actionCancellation = undefined;

    let state = this.state.getState();
    if (refreshDisplayLevel && this.rulerDisplayLevelController) {
      const displayLevel = this.rulerDisplayLevelController.getLevel();
      if(displayLevel !== undefined){
        state.rulers.forEach(r => r.displayLevel = displayLevel);
      } 
    }
    this.view.refreshGameView(state, true, false);
  }

  dispose(): void {
    //this.state.dispose();
    this.view.dispose();
  }
}
