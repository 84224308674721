import { Container } from 'pixi.js';
import { TutorialPointerView } from '../tutorialHand/TutorialPointerView';
import { CancellationTokenSource } from '@puzzles/core/async/cancellations';
import { wait, waitUntil } from '@puzzles/core/async/awaitable';
import { Disposable } from '@pixi-spine/all-4.1';
import { PuzzleActivityController } from './puzzle-activity-controller';

export class FractionTutorialView extends Container implements Disposable {
  private pointer: TutorialPointerView;
  private context: PuzzleActivityController;
  protected cancellation?: CancellationTokenSource;
  private currentSlot: Container;

  constructor(context: PuzzleActivityController, currentSlot: Container) {
    super();

    this.context = context;
    context.view.actionViewController.dragController.onDragStart(this.stop.bind(this));
    context.view.actionViewController.dragController.onDragEnd(this.play.bind(this));
    this.currentSlot = currentSlot;

    this.pointer = new TutorialPointerView();
    this.pointer.moveSpeed = 25;

    this.interactiveChildren = false;
    this.addChild(this.pointer);
  }

  dispose(): void {
    this.stop();
    this.destroy();
  }

  public async play() {
    let menuView = this.context.view.actionView;
    if (menuView.targetSlots.filter(s => s.element).length > 0) {
      this.dispose();
      return;
    }

    this.cancellation = new CancellationTokenSource();
    let token = this.cancellation.token;

    if (token.isCancelled) return;
    await wait(100);
    await waitUntil(() => this.context.view.actionViewController.InteractionEnabled);
    await wait(1000);

    while (true) {
      if (token.isCancelled) return;

      let sourcePos = this.currentSlot.getGlobalPosition();

      this.pointer.position.set(sourcePos.x, sourcePos.y);
      await this.pointer.pointerDown();
      await this.pointer.moveTo(menuView.targetSlots[0].slot.getGlobalPosition());

      if (token.isCancelled) return;
      await wait(800);
    }
  }

  public stop() {
    this.cancellation?.cancel();
    this.pointer.pointerUp();
  }
}
