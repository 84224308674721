import { cloneState, PuzzleState } from './puzzle-state';

export class PuzzleStateController {
  private state: PuzzleState;
  private stateStack: PuzzleState[] = [];
  private stateHistory: PuzzleState[] = [];
  private stateHistoryIndex = 0;
  private stateHistoryMax = 10;

  constructor(state: PuzzleState) {
    this.state = state;
  }

  public pushState() {
    this.stateStack.push(this.state);
    this.state = cloneState(this.state);
  }

  public popState() {
    if (this.stateStack.length > 0) {
      this.state = this.stateStack.pop()!;
    }
  }

  public getState(): PuzzleState {
    return this.state;
  }

  public saveState() {
    this.stateHistory.push(cloneState(this.state));
    this.stateHistoryIndex = this.stateHistory.length;
    if (this.stateHistory.length > this.stateHistoryMax) {
      this.stateHistory.splice(0, 1);
    }
  }

  public undo() {
    if (this.stateHistoryIndex > 0) {
      this.stateHistoryIndex--;
      this.state = this.stateHistory[this.stateHistoryIndex];
    }
  }

  public redo() {
    if (this.stateHistoryIndex < this.stateHistory.length - 1) {
      this.stateHistoryIndex++;
      this.state = this.stateHistory[this.stateHistoryIndex];
    }
  }
}
