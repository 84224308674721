import { Container, Graphics, Text } from 'pixi.js';
import { Rect } from '../core/math/rectangle';
import { CharacterView } from './character-view';
import { CancellationToken } from '../core/async/cancellations';
import { CharacterFeedbackController } from './character-feedback-controller';

import gsap from 'gsap';

export class DuckTransitionView extends Container {
  private text: Text;
  private graphics: Graphics;
  private character: CharacterView;
  private characterFeedback: CharacterFeedbackController;
  container: Rect = { x: 0, y: 0, width: 100, height: 100 };
  animation?: gsap.core.Timeline;

  constructor(text: string = 'Level Complete!', anim: 'jump' | 'happy' = 'jump') {
    super();
    this.graphics = new Graphics();
    this.character = new CharacterView();
    this.characterFeedback = new CharacterFeedbackController(this.character, false);
    if (anim == 'jump') this.character.jump(new CancellationToken(), false);
    else this.character.happy();
    this.text = new Text(text, {
      fontFamily: 'Montserrat',
      fontSize: 24,
      fill: 0xffffff,
      align: 'center',
    });
    this.text.anchor.set(0.5);

    this.graphics.alpha = 0;
    this.character.alpha = 0;
    this.characterFeedback.alpha = 0;
    this.text.alpha = 0;

    this.addChild(this.graphics);
    this.addChild(this.character);
    if (anim == 'jump') this.addChild(this.characterFeedback);
    this.addChild(this.text);
  }

  resize(container: Rect) {
    this.container = container;
    this.character.pivot.set(0.5);
    var ratio = this.character.width / this.character.height;
    this.character.width = Math.min(container.width, container.height) / 4;
    this.character.height = this.character.width / ratio;
    this.character.position.set(
      container.width / 2,
      container.height / 2 + this.character.height / 2,
    );
    this.characterFeedback.scale.set(this.character.scale.x);
    this.text.y = container.height / 4;
    this.text.x = container.width / 2;
    this.text.style.fontSize = (container.width - container.width / 3) / this.text.text.length;
    this.draw();
  }

  draw(container: Rect = this.container) {
    this.graphics.clear();
    this.graphics.lineStyle(0);
    this.graphics.beginFill(0x000000, 1);
    this.graphics.drawRect(container.x, container.y, container.width, container.height);
    this.graphics.endFill();
  }

  async in(): Promise<void> {
    this.alpha = 1;
    this.animation?.kill();
    this.animation = gsap
      .timeline()
      .from(this.character.scale, { x: 0, y: 0, ease: 'back.in', duration: 0.4 })
      .to(this.graphics, { alpha: 1, duration: 0.3 })
      .to(this.character, { alpha: 1, duration: 0.15 }, 0.3)
      .to(this.characterFeedback, { alpha: 1, duration: 0.15 }, 0.3)
      .to(this.text, { alpha: 1, duration: 0.15 }, 0.3);
    await this.animation;
  }

  async out(): Promise<void> {
    this.animation?.kill();
    this.animation = gsap
      .timeline()
      .to(this.character.scale, { x: 0, y: 0, ease: 'back.in', duration: 0.3 })
      .to(this.character, { alpha: 0, duration: 0.1 }, 0.25)
      .to(this.characterFeedback, { alpha: 0, duration: 0.1 })
      .to(this.text, { alpha: 0, duration: 0.1 })
      .to(this.graphics, { alpha: 0, duration: 0.3 });

    await this.animation;
    this.alpha = 0;
  }
}
