import './duck-fraction-activity.scss';
import '../core/activity/activity-component.scss';

import { Application, Assets, Ticker } from 'pixi.js';
import { ActivityReport, IActivityPlayer } from '../core/activity/activity-player';
import { DisplayScaler } from '../core/pixi/display-scaler';
import { Disposable } from '../core/interfaces/disposable';
import { initializePixiInspector } from '../core/pixi/pixi-inspector';
import { PuzzleActivityController } from './puzzle-activity-controller';
import { PuzzleData } from './puzzle-data';
import { createState, findAllSolutions, PuzzleState } from './puzzle-state';
import { DisplayListTickableObserver, TickableManager } from '../core/tickable-manager';
import { PuzzleSkinController, getVehicleAssetClass, PuzzleSkinData } from './puzzle-skin';
import { assetBundleItem, loadAssetBundles } from '../core/asset-utility';
import { CharacterView } from './character-view';
import { PortalView } from './entityviews/portal-view';
import FontFaceObserver from 'fontfaceobserver';
import { DuckTransitionView } from './duck-transition-view';
import { FractionTutorialView } from './fraction-tutorial-view';
import { ActionMenuButtonView } from './action-menu-button-view';
import { permutations } from '@puzzles/core/math/utility';
import { sound } from '@pixi/sound';
import { ActionViewController } from './action-view-controller';
import { CharacterFeedbackController } from './character-feedback-controller';
import { PuzzleActivityViewController } from './puzzle-activity-view-controller';
import { BatteryView } from './entityviews/battery-view';
import { TutorialPointerView } from 'src/tutorialHand/TutorialPointerView';
import { VehicleProgressView } from './entityviews/vehicle-progress-view';

export type DuckFractionActivityReport = ActivityReport<PuzzleData> & {
  triesCombinationRatio: number;
};

export class DuckFractionActivity implements IActivityPlayer<PuzzleData, DuckFractionActivityReport>, Disposable {
  application: Application;
  disposed: boolean = false;
  displayScaler: DisplayScaler;
  controller!: PuzzleActivityController;
  private tickableManager: TickableManager;

  protected playTutorial: boolean;
  protected isFirstPuzzle: boolean = true;
  private tutorial?: FractionTutorialView;
  protected skinController: PuzzleSkinController = new PuzzleSkinController();

  constructor(container: HTMLElement, playTutorial: boolean = false) {
    sound.volumeAll = 0;
    this.playTutorial = playTutorial;

    this.application = new Application({
      resizeTo: container,
      autoDensity: true,
      antialias: true,
      resolution: window.devicePixelRatio || 2,
    });
    container.appendChild(this.application.view as any);

    initializePixiInspector(this.application);

    let stage = this.application.stage;
    stage.hitArea = this.application.screen;
    stage.interactive = true;
    stage.sortableChildren = true;

    this.tickableManager = new TickableManager();
    new DisplayListTickableObserver(stage, this.tickableManager);
    Ticker.shared.add(this.tick, this);

    this.displayScaler = new DisplayScaler(
      this.application.stage,
      {
        x: 0,
        y: 0,
        width: 1024,
        height: 768,
      },
      0.5
    );
    this.onResize = this.onResize.bind(this);
    window.addEventListener('resize', this.onResize);
  }

  async initialize(progression: (progress: number) => void = () => {}) {
    if (this.disposed) return;

    await new FontFaceObserver('Montserrat').load();
    await loadAssetBundles([
        CharacterView, 
        BatteryView,
        PortalView, 
        VehicleProgressView,
        ActionMenuButtonView, 
        ActionViewController, 
        CharacterFeedbackController,
        PuzzleActivityViewController,
        TutorialPointerView,
      ], progression);
  }

  async play(configuration: PuzzleData): Promise<DuckFractionActivityReport | undefined> {
    const skin = this.skinController.getSkin();
    await this.loadPuzzleSkin(skin);

    const state = createState(configuration);
    this.refreshController(state, skin);

    return new Promise((resolve) => {
      const time = Date.now() / 1000;

      if (Object.hasOwn(configuration, 'tutorial')) {
        this.playTutorial = (configuration as any).tutorial;
      }
      if (this.playTutorial) this.startTutorial(state);

      this.application.stage.sortChildren();
      this.onResize();

      this.controller.onComplete = (triesNumber) => {
        this.skinController.addLevel();
        this.isFirstPuzzle = false;
        this.playTutorial = false;

        const sourceSlotsCount = this.controller.view.actionView.sourceSlots.length;
        const targetSlotsCount = this.controller.view.actionView.targetSlots.length;
        const permutationsCount = permutations(sourceSlotsCount, targetSlotsCount);
        const triesCombinationRatio = permutationsCount > 1 ? (triesNumber - 1) / (permutationsCount - 1) : 0;

        resolve({
          success: true,
          elapsedTime: Date.now() / 1000 - time,
          score: 0,
          tries: triesNumber,
          triesCombinationRatio: triesCombinationRatio,
          config: configuration,
        });
        return true;
      };
    });
  }

  protected refreshController(state: PuzzleState, skin: PuzzleSkinData){
    const worldChanged = this.skinController.didWorldChanged;
    this.skinController.didWorldChanged = false;

    const oldController = this.controller;
    
    this.controller = new PuzzleActivityController(
      state,
      skin,
      this.application.stage,
      this.application.screen,
      this.isFirstPuzzle ? 400 : 1000,
      worldChanged
    );

    oldController?.dispose();
  }

  protected async loadPuzzleSkin(skin: PuzzleSkinData){
    await new FontFaceObserver('Montserrat').load();
    await loadAssetBundles([
      getVehicleAssetClass(skin.vehicle),
      assetBundleItem('worldAssets', { background: skin.background }),
    ]);
  }

  protected startTutorial(state: PuzzleState){
    const fraction = findAllSolutions(state)[0].done[0];
    let slot = this.controller.view.actionView.sourceSlots.find(
      (s) =>
        s.element?.props.value.numerator == fraction.numerator &&
        s.element?.props.value.denominator == fraction.denominator
    )!.slot;
    if(!this.tutorial){
      this.tutorial = new FractionTutorialView(this.controller, slot);
      this.tutorial.zIndex = 9999;
      this.application.stage.addChild(this.tutorial);
    }
    this.tutorial.play();
  }

  dispose(): void {
    this.disposed = true;
    Assets.reset();
    //await unloadAssetBundles([NoomView, PipeView]); // creates problems
    Ticker.shared.remove(this.tick, this);
    this.application.destroy(true);
    this.displayScaler.dispose();
    window.removeEventListener('resize', this.onResize);
  }

  tick(delta: number): void {
    this.tickableManager.tick(delta);
  }

  onResize() {
    let screen = this.application.screen;

    this.application.resize();
    this.application.stage.hitArea = screen;
    this.displayScaler.resize(screen);
    this.controller.resize(screen);

    for (var child of this.application.stage.children) {
      if (child instanceof DuckTransitionView) (child as DuckTransitionView).resize(screen);
    }
  }
}
